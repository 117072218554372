<template>
  <v-row class="mb-3">
    <v-col
      ><v-select
        :label='$t("view.PageBackendEvent.template_name")'
        class="white"
        :items="data"
        :item-text="name"
        outlined
        placeholder="Template Name"
        v-model="id"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect"],
   data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.callbackSelect(this.id);
    },
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/"+this.$_getLocale()+"/data/questionSet";
      this.$api.fetch(this.api);
    },
    name: (item) => item.name,
  },
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
</style>