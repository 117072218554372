<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-row class="mb-3"> <h1>{{$t("view.PageBackendEvent.template")}}</h1></v-row>
    <v-row class="mt-1">
      <v-col cols="12" md="8">
        <QuestionSet :callbackSelect="selectQuestionSet" />
      </v-col>
      <v-col cols="12" md="4">
        <v-btn
          class="mt-3"
          :loading="api.isLoading"
          @click="submit"
          elevation="2"
          color="primary"
        >
          {{$t("action.save")}}
        </v-btn>
    
       <v-btn
       class="mt-3 ml-3"
          text
          @click="$router.go(-1)"
        >
           {{$t("action.cancel")}}
        </v-btn>
      </v-col>
    </v-row>

    <div><Question ref="question_set" :questionSetId="questionSetId" :callbackForm="selectQuestionSetQuestion" /></div>
  </v-container>
</template>

<script>
import QuestionSet from "@/components/Bread/Checkpoint/Template/QuestionSet";
import Question from "@/components/Bread/Checkpoint/Template/Question";
export default {
  props:["url","callbackSuccess"],
  components: {
    QuestionSet,
    Question,
  },
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
    questionSetId: null,
    form:[]
  }),

  methods: {
    selectQuestionSet(item) {
      this.questionSetId = item;
      var that = this;
      setTimeout(() => {
        that.$refs["question_set"].fetch();
      }, 100);
    },
    selectQuestionSetQuestion(form){
      this.form = form;
    },
    showError(error) {
      this.api.isError = true;
      this.api.error = error;
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
  },

  created() {
    console.log(this.self);
    console.log(this.form);
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },

};
</script>

<style>
</style>