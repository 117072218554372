<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <PageTemplate
      :role="role"
      :model="model"
      :url="`${$api.servers.moderator}/v1/event/${$route.params.parentId}/checkpoint/add`"
      action="add"
      :callbackSuccess="callbackSuccess"
    ></PageTemplate>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/event";
import model from "@/models/items/eventTopic";
//EOC
import PageTemplate from "@/components/Bread/Checkpoint/Template/PageTemplate";
import { mapState } from "vuex";
export default {
  components: {
    PageTemplate,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["parent"],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.parent && this.parent.name) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent),
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Checkpoint'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:path,
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("string.bread_template"),
        to:{name:'PageModeratorEventCheckpointTemplate',params:{parentId:this.$route.params.parentId,parent:this.parent}},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        this.$router.push({ name: "PageModeratorEventCheckpointManage"})
      },
    }
};
</script>