var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dessertHeaders,"loading":_vm.api ? _vm.api.isLoading : false,"items":_vm.question,"expanded":_vm.expanded,"item-key":"name","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("model.prop.total"))+" "+_vm._s(_vm.data.length)+" "+_vm._s(_vm.$t("model.name.questions")))])],1)]},proxy:true},{key:"item.chapter_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.chapter_sort)+". "+_vm._s(item.chapter_name))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticStyle:{"-webkit-transform":"scale(0.8)","-moz-transform":"scale(0.8)","-ms-transform":"scale(0.8)","transform":"scale(0.8)"},domProps:{"innerHTML":_vm._s(item.question_code.question)}})])]}},{key:"item.expand",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"view-question text-right",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.show(item)}}},[_vm._v(" "+_vm._s(_vm.$t("action.viewSample"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }