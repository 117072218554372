<template>
  <v-data-table
    :headers="dessertHeaders"
    :loading="api ? api.isLoading : false"
    :items="question"
    :expanded.sync="expanded"
    item-key="name"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{$t("model.prop.total")}} {{ data.length }} {{$t("model.name.questions")}}</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.chapter_name="{ item }">
      <div>{{ item.chapter_sort }}. {{ item.chapter_name }}</div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div
          style="
            -webkit-transform: scale(0.8);
            -moz-transform: scale(0.8);
            -ms-transform: scale(0.8);
            transform: scale(0.8);
          "
          v-html="item.question_code.question"
        ></div>
      </td>
    </template>
    <template v-slot:item.expand="{ item }">
      <div class="text-right">
        <v-btn
          @click="show(item)"
          class="view-question text-right"
          color="primary"
          text
        >
          {{$t("action.viewSample")}}
          <v-icon right>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
export default {
  props: ["questionSetId", "callbackForm"],
  data() {
    return {
      items: [],
      question: [],
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
      data: [],
      id: null,
      item: null,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: this.$t("model.name.chapter"),
          align: "start",
          sortable: false,
          value: "chapter_name",
        },
        { text: this.$t("model.name.question"), sortable: false, value: "name" },
        { text: "", value: "expand" },
      ],
    };
  },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.callbackForm(this.data);
      for (var i = 0, l = this.data.length; i < l; i++) {
        this.filterItems(this.data[i].chapterId, this.data[i].questionKey);
      }
    };
    //EOC
  },

  methods: {
   
    show(value) {
      const index = this.expanded.indexOf(value);
      if (index === -1) {
        this.expanded.push(value);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/" +
        this.$_getLocale() +
        "/data/questionSet/" +
        this.questionSetId +
        "/questionSetQuestion";
      this.$api.fetch(this.api);
    },
    name: (item) => item.name,

    filterItems(chapterId, questionKey) {
      this.question = [];
      axios
        .get(
          this.$api.servers.question +
            "/chapters/" +
            chapterId +
            "/questions?lang=" +
            this.$_getLocale()
        )
        .then((response) => {
          this.items = response.data;
          for (var j = 0, k = this.items.length; j < k; j++) {
            if (this.items[j].key == questionKey) {
              this.question.push(this.items[j]);
            }
          }
        });
    },
  },
};
</script>

<style>
.view-question::before {
  background-color: transparent !important;
}
</style>